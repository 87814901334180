<template>
  <div>
    <el-tabs v-model="activeName" class="fullScreenMainCon">
      <el-tab-pane label="基本信息" name="基本信息" v-if="form.agreementInformation">
        <el-card>
          <el-descriptions
            direction="vertical"
            style="width: 100%"
            title="基础信息"
            :column="7"
            border
          >
            <el-descriptions-item :span="2">
              <template slot="label">合同名称 </template>
              <el-input
                disabled
                v-model="form.agreementInformation.agreementName"
                placeholder="请输入合同名称"
              ></el-input>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 合同编号 </template>
              <el-input disabled v-model="form.agreementInformation.agreementNum"></el-input>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 实际合同金额(元) </template>

              <el-input
                disabled
                v-model="form.agreementInformation.actualContractAmount"
              ></el-input>
            </el-descriptions-item>
            <!-- <el-descriptions-item>
              <template slot="label"> 实际金额(元) </template>

              <el-input disabled v-model="form.agreementInformation.actualAmount"></el-input>
            </el-descriptions-item> -->
            <el-descriptions-item>
              <template slot="label"> 签订时间 </template>

              <el-date-picker
                disabled
                value-format="timestamp"
                type="date"
                v-model="form.agreementInformation.signedDate"
                placeholder="请选择签订时间"
              ></el-date-picker>
            </el-descriptions-item>
            <el-descriptions-item :span="2">
              <template slot="label"> 签订地点 </template>
              <el-input
                type="textarea"
                autosize
                disabled
                v-model="form.agreementInformation.signedPlace"
                placeholder="请输入签订地点"
              ></el-input>
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label"> 项目承接公司 </template>
              <Dictionary
                disabled
                v-model="form.agreementInformation.companyType"
                code="COMPANY_TYPE"
                placeholder="请选择项目承接公司"
              />
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">合同类型 </template>
              <!-- <Dictionary
                disabled
                v-model="form.agreementInformation.agreementType"
                code="TYPE_CONTRACT"
                placeholder="请选择合同类型"
              /> -->
              <el-radio-group v-model="form.agreementInformation.agreementType">
                <el-radio
                  disabled
                  v-for="item in dictData.agreementType"
                  :key="item.id"
                  :label="item.dictVal"
                  style="margin-bottom: 10px"
                  >{{ item.dictName }}</el-radio
                >
              </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 业务种类 </template>
              <Dictionary
                disabled
                v-model="form.agreementInformation.businessLines"
                code="BUSINESS_LINES"
                placeholder="请选择业务类型"
              />
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 合同属性 </template>
              <Dictionary
                disabled
                v-model="form.agreementInformation.agreementAttribute"
                code="CONTRACT_ATTRIBUTE_TYPE"
                placeholder="请选择合同属性"
              />
            </el-descriptions-item>
            <!-- 2024-02-22-隐藏项目种类 -->
            <!-- <el-descriptions-item>
              <template slot="label"> 项目种类 </template>
              <Dictionary
                disabled
                v-model="form.agreementInformation.projectLines"
                :code="form.agreementInformation.businessLines"
                placeholder="请选择项目种类"
              />
            </el-descriptions-item> -->
            <el-descriptions-item :span="3">
              <template slot="label"> 项目来源 </template>
              <el-radio-group v-model="form.agreementInformation.projectSource">
                <div class="radio-group">
                  <div class="radio-label">生产类型：</div>
                  <div class="radio-item">
                    <el-radio
                      disabled
                      v-for="item in produceList"
                      :key="item.id"
                      :label="item.dictVal"
                      >{{ item.dictName }}</el-radio
                    >
                  </div>
                </div>
                <div class="radio-group">
                  <div class="radio-label">非生产类型：</div>
                  <div class="radio-item">
                    <el-radio
                      disabled
                      v-for="item in unProduceList"
                      :key="item.id"
                      :label="item.dictVal"
                      >{{ item.dictName }}</el-radio
                    >
                  </div>
                </div>
              </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item :span="7">
              <template slot="label"> 合同备注 </template>
              <el-input
                type="textarea"
                disabled
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="form.agreementInformation.remark"
              ></el-input>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            title="执行完毕处理信息"
            direction="vertical"
            v-if="form.agreementInformation.status == 30"
            :column="10"
            border
            style="width: 100%; margin-top: 10px"
          >
            <el-descriptions-item label="处理人">{{
              form.agreementInformation.executionCompletedStaff
            }}</el-descriptions-item>
            <el-descriptions-item label="处理时间">{{
              form.agreementInformation.executionCompletedDate | dateFormat
            }}</el-descriptions-item>
            <el-descriptions-item label="处理备注" :span="8">{{
              form.agreementInformation.executionCompletedRemark
            }}</el-descriptions-item>
          </el-descriptions>
          <div style="color: #000; margin-top: 10px; font-weight: 700; font-size: 16px">
            关联项目列表
          </div>
          <el-table border :data="form.agreementInformation.projectListResponseList">
            <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="false"
              width="400"
            ></el-table-column>
            <el-table-column align="center" prop="theirDeptName" label="所属部门"></el-table-column>
            <el-table-column
              align="center"
              prop="deptClassifyManagerName"
              label="项目经理"
            ></el-table-column>
            <el-table-column prop="projectPredictStartDate" align="center" label="项目预计开始时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="projectPredictEndDate" align="center" label="项目预计结束时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" label="项目状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.projectStatus) }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-card>
          <div slot="header">
            <span>工作内容</span>
          </div>
          <el-table border :data="form.agreementWorkContentList">
            <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="工作内容" align="center">
              <template slot-scope="scope">
                <el-input disabled v-model="scope.row.content" placeholder="请输入"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-card>
          <div slot="header">
            <span id="cost">费用结算与付款方式</span>
          </div>
          <el-table border :data="form.agreementStageList">
            <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="stageName" label="阶段" align="center"> </el-table-column>
            <el-table-column label="内容" prop="paymentStandard" align="center"> </el-table-column>
            <el-table-column label="金额（元）" prop="paymentAmount" align="center">
            </el-table-column>
            <el-table-column
              label="约定付款时间"
              width="180"
              align="center"
              v-if="form.agreementInformation.agreementAttribute == 'cai_gou_he_tong'"
            >
              <template slot-scope="scope">
                {{ scope.row.paymentDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column v-else label="约定收款时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.proceedsDate | dateFormat }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-card>
          <div slot="header">
            <span>签署信息</span>
          </div>
          <div style="width: 100%">
            <el-table :data="form.agreementSignedList" border>
              <el-table-column label="合同方" fixed="left" width="100">
                <template slot-scope="scope">
                  {{ scope.row.signedType | dict(agreementSignedTypeData) }}
                </template>
              </el-table-column>

              <el-table-column label="单位名称" prop="party" width="200"> </el-table-column>

              <el-table-column label="电话" prop="partyPhone" width="200"> </el-table-column>

              <el-table-column label="传真" prop="partyFax" width="200"> </el-table-column>

              <el-table-column label="邮箱" prop="partyMail" width="200"> </el-table-column>

              <el-table-column label="地址" prop="partyAddress" width="200"> </el-table-column>

              <el-table-column label="开户银行" prop="partyDepositBank" width="220">
              </el-table-column>

              <el-table-column label="银行账号" prop="partyAccountBank" width="250">
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane name="附件" label="附件"
        ><Annex name="annex" ref="annex" :options="annex" :value="form.agreementFileList"
      /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'ContractParticulars',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    Annex: () => import('@/views/agreement/Annex.vue'),
  },
  props: {
    form: {
      type: Object,
      default: function () {
        return {}
      },
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      activeName: '基本信息',
      agreementSignedTypeData: [],
      dictData: {
        projectStatus: [],
        agreementType: [],
      },
      produceList: [],
      unProduceList: [],
      annex: {
        ProjectDisable: 5,
      },
    }
  },
  provide() {
    return {
      form: this.form,
    }
  },
  created() {
    this.getType()
  },
  watch: {},
  filters: {},
  methods: {
    getType() {
      this.$api.dict
        .listSysDictData('AGREEMENT_SIGNED_TYPE_MULTIPLE', true)
        .then(res => {
          this.agreementSignedTypeData = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('TYPE_CONTRACT', true)
        .then(res => {
          this.dictData.agreementType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_STATUS', true)
        .then(res => {
          this.dictData.projectStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          const produceList = []
          const unProduceList = []
          res.data.forEach(item => {
            switch (item.dictVal) {
              case 'yi_shou_zhi_qian':
                produceList.push(item)
                break
              case 'zi_zhi_he_zuo':
                produceList.push(item)
                break
              case 'fen_bao_zhuan_bao':
                produceList.push(item)
                break
              case 'zi_zhi_ti_gong':
                unProduceList.push(item)
                break
              case 'zheng_ti_wai_xie':
                unProduceList.push(item)
                break
              case 'shang_wu_ju_jian':
                unProduceList.push(item)
                break
            }
          })
          this.produceList = produceList
          this.unProduceList = unProduceList
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-checkbox-group {
  line-height: 28px;
  .el-checkbox {
    margin-right: 8px;
  }
}
.el-radio-group {
  line-height: 28px;
  .el-radio {
    position: relative;
    top: 5px;
    margin-right: 8px;
  }
}
</style>
